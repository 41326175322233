import classNames from "classnames";

export enum links {
  home = "/",
  document = "http://wiki.doyouquant.com/",
  tutorial = "http://wiki.doyouquant.com/",
  download = "/download.html",
  pricing = "/pricing.html",
}

export function Page(props: {
  children: React.ReactNode;
  scripts?: string[];
  path?: string;
  title?: string;
}) {
  const children = (
    <>
      <Header path={props.path} />
      {props.children}
      <Footer />
    </>
  );
  if (typeof window == "undefined") {
    return (
      <html>
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>{props.title ? `${props.title} - ` : ""}多元量化科技</title>
          <meta
            name="description"
            content="多元量化科技专注于AI人工智能开发研究，金融资产管理系统、证券/期货程序化快速交易系统和量化策略服务的开发、供给、植入，将领先的 IT 技术与丰富的金融行业经验相结合，为全球金融投资机构和金融投资者提供高效的量化金融资产管理解决方案和配套服务。"
          />
          <link rel="icon" href={require("./images/app-icon.png")} />
          <link rel="stylesheet" href={require("./index.css")} />
        </head>
        {props.scripts?.map((src) => (
          <script src={Reflect.get(globalThis, "public") + src} async></script>
        ))}
        <body class="bg-[#f3f9ff]">{children}</body>
      </html>
    );
  } else {
    return children;
  }
}

function Header(props: { path?: string }) {
  const navs = [
    { name: "首页", href: links.home },
    { name: "文档", href: links.document },
    { name: "教程", href: links.tutorial },
    { name: "下载", href: links.download },
    { name: "定价", href: links.pricing },
  ];
  const active = navs.findIndex((nav) => nav.href == props.path);
  return (
    <header class="h-16 sm:h-20 transition-all bg-white drop-shadow-md relative z-10">
      <div class="main-container h-full flex items-center">
        <a href={links.home} class="mr-8">
          <img class="h-10 sm:h-12" src={require("./images/logo.png")} />
        </a>
        <div class="hidden sm:block">
          {navs.map((nav, index) => (
            <a
              class={classNames(
                "px-4 hover:text-black transition-all",
                index == active ? "" : "text-gray-500"
              )}
              href={nav.href}
            >
              {nav.name}
            </a>
          ))}
        </div>
      </div>
    </header>
  );
}

function Footer() {
  const items = {
    热门链接: [
      ["帮助中心", links.tutorial],
      ["软件下载", links.download],
    ],
    支持: [
      ["免责条款", "/legal/免责声明.html"],
      ["隐私条款", "/legal/隐私政策.html"],
    ],
    合作: [
      ["定价", links.pricing],
      ["业务合作", "/"],
    ],
    监管机构: [
      ["中国证监会", "http://www.csrc.gov.cn/"],
      ["中国期货协会", "http://www.cfachina.org/"],
      ["中国期货市场监管中心", "http://www.cfmmc.com/"],
    ],
  };
  return (
    <footer class="pt-16 pb-8 leading-relaxed text-xs text-gray-700">
      <div class="main-container">
        <div class="flex flex-col lg:flex-row gap-4">
          <div class="flex-1 flex">
            {Object.entries(items).map(([title, items]) => (
              <div class="flex-1 lg:w-40 lg:flex-none">
                <p class="text-base font-semibold mb-4">{title}</p>
                {items.map(([name, href]) => (
                  <a class="block mb-2" href={href}>
                    {name}
                  </a>
                ))}
              </div>
            ))}
          </div>
          <div class="w-60 flex justify-between">
            <div class="w-28">
              <p class="text-center font-semibold text-base mb-2">公众号</p>
              <img
                src={require("./images/qrcode-wechat.png")}
                class="w-full"
                loading="lazy"
              />
            </div>
            <div class="w-28">
              <p class="text-center font-semibold text-base mb-2">微信客服</p>
              <img
                src={require("./images/qrcode-wechat-cs.png")}
                class="w-full"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <hr class="pb-8 mt-6" />
        <div class="flex flex-col lg:flex-row gap-4">
          <div class="flex-1">
            <p class="mb-4">
              广西多元量化科技有限公司
              <br />
              中国公司地址：中国（广西）自由贸易试验区南宁片区五象大道403号富雅国际金融中心G1栋38层3813号
            </p>
            <p>
              多元量化科技有限公司
              <br />
              香港公司地址：香港旺角亞皆老街98號富都大廈2樓22室
            </p>
          </div>
          <div class="w-60">
            <p>
              邮箱：
              <a href="mailto:service@doyouquant.com">service@doyouquant.com</a>
            </p>
            <p>
              客服电话：<a href="tel:18607819972">18607819972</a>，
              <a href="tel:18607819904">18607819904</a>
            </p>
            <p class="mt-2 mb-1">关注我们</p>
            <p class="flex gap-2">
              <a href="https://v.douyin.com/ihaHnWq6/">
                <img
                  src={require("./images/icon-douyin.png")}
                  class="h-6"
                  loading="lazy"
                />
              </a>
              {/*
              <a href="">
                <img
                  src={require("./images/icon-tencent-video.png")}
                  class="h-6"
                />
              </a>
              */}
              <a href="https://www.xiaohongshu.com/user/profile/63769e00000000001f018faa?xhsshare=CopyLink&appuid=5f05f3910000000001003b03&apptime=1725263780">
                <img
                  src={require("./images/icon-xiaohongshu.png")}
                  class="h-6"
                  loading="lazy"
                />
              </a>
              <a href="https://space.bilibili.com/3546696917190684">
                <img
                  src={require("./images/icon-bilibili.png")}
                  class="h-6"
                  loading="lazy"
                />
              </a>
            </p>
          </div>
        </div>
        <p class="text-center mt-16">
          © {new Date().getFullYear()} 多元量化科技版权所有
          桂ICP备2024021442号-1
        </p>
      </div>
    </footer>
  );
}
