export function Section6() {
  return (
    <section
      id="section6"
      class={`py-8 sm:py-20 bg-[url(${require("../../images/home-section6-bg.png")})] bg-cover`}
    >
      <h3 class="text-3xl sm:text-4xl font-semibold text-center mb-8 sm:mb-16">
        深受企业客户的信赖
      </h3>
      <div class="main-container flex justify-center">
        <img
          src={require("../../images/home-section6-img.png")}
          class="lg:h-56"
          loading="lazy"
        />
      </div>
      <div class="flex justify-center mt-8 sm:mt-16">
        <a
          class="btn-primary w-40 h-12 sm:w-56 sm:h-16 text-base sm:text-2xl"
          href="/download.html"
        >
          立即体验
        </a>
      </div>
    </section>
  );
}
